import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractService } from '../../commons/abstract.service';
import { ChamadaComposite } from '../../models/chamada.composite';
import { Senha } from '../../models/senha.model';

@Injectable({ providedIn: 'root' })
export class PublicSenhaService extends AbstractService<Senha> {

  getURLBase(): string {
    return '/public/senhas/';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: Senha): void {
    delete obj._id;
  }
  preUpdate(obj: Senha): void {
  }

  getChamadaDiariaPorFila(idFila: string): Observable<ChamadaComposite | any> {
    return this.http.get(this.getURLBase() + 'chamada/' + idFila);
  }

  chamarProximaSenha(idFila: string, idMesa: string): Observable<Senha | any> {
    return this.http.get(this.getURLBase() + 'chamarProximaSenha/' + idFila + '/' + idMesa);
  }

  chamarSenhaNaChamada(idFila: string, idMesa: string, senhaChamada: string): Observable<Senha | any> {
    return this.http.get(this.getURLBase() + 'chamarSenhaNaChamada/' + idFila + '/' + idMesa + '/' + senhaChamada);
  }

  rechamarSenha(idSenha: string): Observable<Senha | any> {
    return this.http.get(this.getURLBase() + 'rechamarSenha/' + idSenha);
  }

  encaminhar(idFila: string, idSenha: string): Observable<Senha | any> {
    return this.http.get(this.getURLBase() + 'encaminhar/' + idFila + "/" + idSenha);
  }

  countChamadaPorFila(idFila: string): Observable<number | any> {
    return this.http.get('/senhas/count/chamadas/' + idFila);
  }

  previsaoAtendimento(idFranquia: string): Observable<number | any> {
    return this.http.get(this.getURLBase() + 'previsaoAtendimento/' + idFranquia);
  }

  historicoChamadas(idFranquia: string) {
    return this.http.get(this.getURLBase() + 'historico/' + idFranquia);
  }

  historicoChamadasPublic(idFranquia: string) {
    return this.http.get('/public/senhas/historico/' + idFranquia);
  }

  gerarNovaSenha(idFranquia: string) {
    return this.http.get(this.getURLBase() + 'gerarNovaSenha/' + idFranquia);
  }

  gerarNovaSenhaPublic(idFranquia: string) {
    return this.http.get('/public/senhas/gerarNovaSenha/' + idFranquia);
  }

  imprimirLocal(url: string) {
    return this.http.get(url);
  }

  getChamadaPorIdSenha(idSenha: string) {
    return super.get('get/chamada/senha/' + idSenha);
  }

  updateTokenSenha(idSenha: string, token: string) {
    return this.http.post(this.getURLBase() + 'update/token/senha/' + idSenha, { token: token });
  }

  habilitarWhatsApp(idSenha: string, nome: string, numero: string, timer: boolean = false) {
    return super.post('habilitar/whatsapp/' + idSenha, { nome, numero, timer });
  }

  gerarNovaSenhaMetadata(idFila, metadata, preferencial = false) {
    return super.post(`gerarNovaSenhaMetadata/${idFila}${preferencial ? '/preferencial' : ''}`, metadata);
  }

  quantidadeFilaPorSenha(idFranquia, idSenha) {
    return super.get('qtd/chamada/franquia/' + idFranquia + '/senha/' + idSenha);
  }

  sairDaFila(idSenha: string) {
    return super.get('sair/da/fila/' + idSenha);
  }

}